// @flow

import { v4 } from 'uuid';
import React, { Fragment } from 'react';
import { useIntl, FormattedMessage, FormattedDate } from 'react-intl';
import { Image, Link, LazyloadPlaceholder, MediaQuery } from '@riseart/common';
import { Section, SectionContent, Wrapper } from '@riseart/layout';
import { GridSquare, GridSquareItem, GridHorizontal, GridHorizontalItem } from '@riseart/grid';
import { HorizontalSlider } from '@riseart/slider';
import { CardArticle } from '@riseart/article';
import { RssIcon } from '@riseart/icons';
import { article as ENUM_ARTICLE, meta as META_ENUM } from 'Enum';
import { useQuery } from 'shared_services/apollo/useQuery';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import { DataProviderPreloaderPage } from 'shared_components/data/providers/preloaders/Page';
import { LayoutDefault } from 'shared_components/layouts/Default';
import { Breadcrumbs } from 'shared_components/common/breadcrumbs/Breadcrumbs';
import { Share } from 'shared_components/common/share/Share';
import {
  generateSrcForCDN,
  PicturePlaceholder,
} from 'shared_components/common/artdirection/picture/Picture';
import { extractImageFileFromData } from 'shared_services/riseart/utils/ImageUtils';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { BlogHeader } from 'shared_components/article/category/Header';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { EmailSubscriptionSection } from 'shared_components/common/subscription/Section';
import { MetaProvider } from 'shared_data/providers/meta/Meta';
import { LDTYPES, generateLDJSON } from 'shared_models/seo/ldJSON';
import { META_SUBSCRIBER_NAME } from 'shared_services/riseart/meta/Meta';
import LANDING_PAGE_QUERY from 'shared_data/queries/articles/listCategoriesAndArticles.graphql';
import FILTER_ARTICLES_QUERY from 'shared_data/queries/articles/filter.graphql';
import {
  raScreenSm,
  raScreenMdMax,
  raScreenLg,
} from '@riseart/antd-provider/dist/website/variables.less';
import {
  blogHomeMainCls,
  blogHomeMainBkgCls,
  blogHomeMainGridCls,
  blogHomeRssBtnCls,
} from 'shared_components/pages/blog/Home.less';
import blogBkgImg from 'shared_components/pages/blog/assets/blog-bkg-pattern.png';

type Props = {
  currentLocale: Object,
};

const LAZYLOAD_ARTICLE_CONFIG = {
  once: true,
  height: 175,
  offset: 200,
  placeholder: <LazyloadPlaceholder height={175} />,
};

const PRELOADER_SUBSCRIBER_IDS = {
  mainQuery: v4(),
  mostRead: v4(),
  curatorsPicks: v4(),
};

/**
 * BlogHomePage
 */
export default function BlogHomePage({ currentLocale }: Props) {
  const { TYPE_POST } = ENUM_ARTICLE.type;
  const { formatMessage } = useIntl();
  const { loading, data, error } = useQuery(LANDING_PAGE_QUERY, {
    variables: {
      inputArticleCategoryFilter: {
        page: 1,
        items: 5,
        type: TYPE_POST,
      },
      inputArticleFilter: {
        items: 5,
        page: 1,
        type: TYPE_POST,
        showcase: 1,
      },
    },
  });
  const { categories: { items: categories } = {}, articles: { items: featuredArticles } = {} } =
    data || {};

  const [mainArticle, ...restFeaturedArticles] = featuredArticles || [];
  const mainArticleUrl =
    mainArticle &&
    UrlAssembler.articleDetail(mainArticle.id, mainArticle.slug, mainArticle.type, {
      locale: currentLocale,
    });
  const mainAuthorUrl =
    mainArticle && mainArticle.author
      ? UrlAssembler.authorDetail(
          mainArticle.author.type,
          mainArticle.author.typeId,
          mainArticle.author.typeAlias,
          { locale: currentLocale },
        )
      : null;
  const origin = LocationManager.get('origin');

  // Filter Articles
  const {
    loading: loadingFilterArticles,
    data: dataFilterArticles,
    error: errorFilterArticles,
  } = useQuery(FILTER_ARTICLES_QUERY, {
    skip: !!error,
    ssr: false,
    variables: {
      inputArticleFilter: {
        items: 20,
        page: 1,
        tag: ENUM_ARTICLE.tag.MOST_READ,
        type: TYPE_POST,
      },
    },
  });
  const { filterArticles: { items: mostReadArticles } = {} } = dataFilterArticles || {};

  const {
    loading: loadingCuratorsPicks,
    data: dataCuratorsPicks,
    error: errorCuratorsPicks,
  } = useQuery(FILTER_ARTICLES_QUERY, {
    ssr: false,
    variables: {
      inputArticleFilter: {
        items: 5,
        page: 1,
        tag: ENUM_ARTICLE.tag.CURATOR_PICK,
        type: TYPE_POST,
      },
    },
  });
  const { filterArticles: { items: curatorArticles = [] } = {} } = dataCuratorsPicks || {};
  const [mainCuratorArticle, ...restCuratorFeaturedArticles] = curatorArticles || [];
  const mainCuratorArticleUrl = mainCuratorArticle
    ? UrlAssembler.articleDetail(
        mainCuratorArticle.id,
        mainCuratorArticle.slug,
        mainCuratorArticle.type,
        {
          locale: currentLocale,
        },
      )
    : null;
  const mainCuratorAuthorUrl =
    mainCuratorArticle && mainCuratorArticle.author
      ? UrlAssembler.authorDetail(
          mainCuratorArticle.author.type,
          mainCuratorArticle.author.typeId,
          mainCuratorArticle.author.typeAlias,
          { locale: currentLocale },
        )
      : null;

  return (
    <LayoutDefault scrollTopOnPathChange>
      {!error ? (
        <Fragment>
          {loading ? (
            <IsomorphicRipple isActive isFullScreen />
          ) : (
            <MetaProvider
              metaSubscriber={META_SUBSCRIBER_NAME.BLOG_HOME_META}
              meta={{
                [META_ENUM.METATYPE.SCRIPT_LD_JSON_ARTICLES_LIST]: generateLDJSON(
                  LDTYPES.CAROUSEL_ARTICLES,
                  featuredArticles.map((item) => {
                    const image =
                      (item.images &&
                        extractImageFileFromData.articleImage(
                          item.images,
                          ENUM_ARTICLE.image.type.TYPE_MAIN_MASTER,
                        )) ||
                      null;
                    return {
                      ...item,
                      image: image
                        ? generateSrcForCDN({
                            ...image,
                            width: 1200,
                          })
                        : null,
                      mainEntityOfPage: {
                        type: 'WebPage',
                        url: UrlAssembler.articleDetail(item.id, item.slug, item.type, {
                          origin,
                          locale: currentLocale,
                        }),
                      },
                    };
                  }),
                ),
              }}
              hasError={!!error || !data}
            />
          )}
          <DataProviderPreloaderPage
            uniqueId={PRELOADER_SUBSCRIBER_IDS.mainQuery}
            attach={loading}
          />
          {mainArticle ? (
            <React.Fragment>
              <Section padding="none">
                <Wrapper>
                  <MediaQuery minWidth={raScreenLg}>
                    <Link
                      external
                      target="_blank"
                      href={LocalizedConfig.get('externalUrls.articleRssFeed')}
                      title={formatMessage({ id: 'common.rssFeed' })}
                      className={blogHomeRssBtnCls}
                    >
                      <RssIcon />
                    </Link>
                  </MediaQuery>
                  <Breadcrumbs
                    links={[
                      {
                        isLink: false,
                        name: formatMessage({ id: 'components.breadcrumbs.blog' }),
                        url: UrlAssembler.blogList({ locale: currentLocale }),
                      },
                    ]}
                  />
                  <BlogHeader
                    title={formatMessage({ id: 'components.blog.landingTitle' })}
                    categories={categories}
                  />
                </Wrapper>
              </Section>
              <Section className={blogHomeMainCls}>
                <Wrapper
                  sizes={{
                    xs: 'wide',
                    sm: 'wide',
                    md: 'wide',
                    lg: 'narrow',
                    xl: 'narrow',
                  }}
                  className={blogHomeMainBkgCls}
                >
                  <Image src={blogBkgImg} alt="Blog Article Pattern" />
                </Wrapper>
                <Wrapper
                  sizes={{
                    xs: 'wide',
                    sm: 'wide',
                    md: 'wide',
                    lg: 'narrow',
                    xl: 'narrow',
                  }}
                >
                  <GridSquare
                    className={blogHomeMainGridCls}
                    columnCount={2}
                    justify="space-between"
                  >
                    <GridSquareItem columnCount={1}>
                      <MediaQuery minWidth={raScreenSm} maxWidth={raScreenMdMax}>
                        {(isTablet) => (
                          <CardArticle
                            displayStyle={1}
                            title={
                              <Link to={mainArticleUrl} title={mainArticle.title}>
                                {mainArticle.title}
                              </Link>
                            }
                            subtitle={
                              mainArticle.categories && mainArticle.categories[0] ? (
                                <Link
                                  to={UrlAssembler.articleCategory(
                                    mainArticle.categories[0].uri,
                                    mainArticle.categories[0].type,
                                    { locale: currentLocale },
                                  )}
                                  title={mainArticle.categories[0].name}
                                >
                                  {mainArticle.categories[0].name}
                                </Link>
                              ) : null
                            }
                            description={mainArticle.summary}
                            date={
                              !isTablet && (
                                <FormattedDate
                                  value={new Date(mainArticle.publishedDate)}
                                  year="numeric"
                                  month="short"
                                  day="2-digit"
                                >
                                  {(dateLabel: string) => dateLabel}
                                </FormattedDate>
                              )
                            }
                            author={
                              <FormattedMessage
                                id="common.byName"
                                values={{
                                  name: mainAuthorUrl ? (
                                    <Link to={mainAuthorUrl} title={mainArticle.author.fullName}>
                                      {mainArticle.author.fullName}
                                    </Link>
                                  ) : (
                                    mainArticle.author.fullName
                                  ),
                                }}
                              />
                            }
                            share={
                              <Share
                                theme="dark"
                                contentKey="article"
                                contentValues={{ title: mainArticle.title }}
                                image={generateSrcForCDN(
                                  extractImageFileFromData.articleImage(mainArticle.images),
                                )}
                                url={`${origin}${mainArticleUrl}`}
                                buttonText={formatMessage({ id: 'common.share' })}
                              />
                            }
                          >
                            <Link to={mainArticleUrl} title={mainArticle.title}>
                              <PicturePlaceholder
                                type="blog.singleCardFeatured"
                                artDirectionKey="article.main"
                                image={extractImageFileFromData.articleImage(mainArticle.images)}
                                alt={mainArticle.title}
                                lazyload={LAZYLOAD_ARTICLE_CONFIG}
                              />
                            </Link>
                          </CardArticle>
                        )}
                      </MediaQuery>
                    </GridSquareItem>
                    {restFeaturedArticles && restFeaturedArticles.length
                      ? restFeaturedArticles.map(
                          ({
                            id,
                            type,
                            slug,
                            title,
                            summary,
                            publishedDate,
                            images,
                            author,
                            categories,
                          }) => {
                            const articleUrl = UrlAssembler.articleDetail(id, slug, type, {
                              locale: currentLocale,
                            });
                            const authorUrl = author
                              ? UrlAssembler.authorDetail(
                                  author.type,
                                  author.typeId,
                                  author.typeAlias,
                                  { locale: currentLocale },
                                )
                              : null;
                            return (
                              <GridSquareItem key={id} columnCount={2}>
                                <MediaQuery minWidth={raScreenSm} maxWidth={raScreenMdMax}>
                                  {(isTablet) => (
                                    <CardArticle
                                      displayStyle={3}
                                      title={
                                        <Link to={articleUrl} title={title}>
                                          {title}
                                        </Link>
                                      }
                                      subtitle={
                                        categories && categories[0] ? (
                                          <Link
                                            to={UrlAssembler.articleCategory(
                                              categories[0].uri,
                                              categories[0].type,
                                              { locale: currentLocale },
                                            )}
                                            title={categories[0].name}
                                          >
                                            {categories[0].name}
                                          </Link>
                                        ) : null
                                      }
                                      description={summary}
                                      date={
                                        !isTablet && (
                                          <FormattedDate
                                            value={new Date(publishedDate)}
                                            year="numeric"
                                            month="short"
                                            day="2-digit"
                                          >
                                            {(dateLabel: string) => dateLabel}
                                          </FormattedDate>
                                        )
                                      }
                                      author={
                                        <FormattedMessage
                                          id="common.byName"
                                          values={{
                                            name: authorUrl ? (
                                              <Link to={authorUrl} title={author.fullName}>
                                                {author.fullName}
                                              </Link>
                                            ) : (
                                              author.fullName
                                            ),
                                          }}
                                        />
                                      }
                                      share={
                                        <Share
                                          theme="dark"
                                          contentKey="article"
                                          contentValues={{ title }}
                                          image={generateSrcForCDN(
                                            extractImageFileFromData.articleImage(images),
                                          )}
                                          url={`${origin}${articleUrl}`}
                                        />
                                      }
                                    >
                                      <Link to={articleUrl} title={title}>
                                        <PicturePlaceholder
                                          type="blog.gridTwoCol"
                                          artDirectionKey="article.main"
                                          image={extractImageFileFromData.articleImage(images)}
                                          alt={title}
                                          lazyload={LAZYLOAD_ARTICLE_CONFIG}
                                        />
                                      </Link>
                                    </CardArticle>
                                  )}
                                </MediaQuery>
                              </GridSquareItem>
                            );
                          },
                        )
                      : null}
                  </GridSquare>
                </Wrapper>
              </Section>
            </React.Fragment>
          ) : null}
          {/* eslint-disable-next-line */}
          {loadingFilterArticles ? (
            <React.Fragment>
              <DataProviderPreloaderPage
                uniqueId={PRELOADER_SUBSCRIBER_IDS.mostRead}
                attach={loadingFilterArticles}
              />
              <Section background="gray">
                <Wrapper>
                  <SectionContent
                    title={formatMessage({ id: 'components.blog.mostRead' })}
                    headingProps={{ align: 'center' }}
                  >
                    <HorizontalSlider columnCount={4} placeholder>
                      {Array.apply(0, Array(parseInt(4, 10) + 1)).map((item, idx) => (
                        <CardArticle key={idx} displayStyle={5} placeholder />
                      ))}
                    </HorizontalSlider>
                  </SectionContent>
                </Wrapper>
              </Section>
            </React.Fragment>
          ) : // eslint-disable-next-line
          errorFilterArticles ? null : (
            <EmailSubscriptionSection
              inputId="blog-subscribe-field"
              inputName="blog-subscribe-email"
              title={formatMessage({ id: 'components.blog.subscribe.title' })}
              subTitle={formatMessage({ id: 'components.blog.subscribe.subtitle' })}
              buttonKey="components.blog.subscribe.submitBtn"
              showSuccessDialog
            >
              {({ SubscribeForm }) => {
                const hasMostReadArticles = mostReadArticles && mostReadArticles.length;

                if (!hasMostReadArticles && !SubscribeForm) {
                  return null;
                }

                return (
                  <Section background="gray">
                    <Wrapper>
                      <SectionContent
                        title={
                          hasMostReadArticles
                            ? formatMessage({ id: 'components.blog.mostRead' })
                            : null
                        }
                        headingProps={{ align: 'center' }}
                      >
                        {hasMostReadArticles ? (
                          <HorizontalSlider columnCount={4}>
                            {mostReadArticles && mostReadArticles.length
                              ? mostReadArticles.map(
                                  ({
                                    id,
                                    type,
                                    slug,
                                    title,
                                    summary,
                                    publishedDate,
                                    images,
                                    author,
                                    categories,
                                  }) => {
                                    const articleUrl = UrlAssembler.articleDetail(id, slug, type, {
                                      locale: currentLocale,
                                    });
                                    const authorUrl = author
                                      ? UrlAssembler.authorDetail(
                                          author.type,
                                          author.typeId,
                                          author.typeAlias,
                                          { locale: currentLocale },
                                        )
                                      : null;
                                    return (
                                      <MediaQuery minWidth={raScreenSm} maxWidth={raScreenMdMax}>
                                        {(isTablet) => (
                                          <CardArticle
                                            key={id}
                                            displayStyle={5}
                                            title={
                                              <Link to={articleUrl} title={title}>
                                                {title}
                                              </Link>
                                            }
                                            subtitle={
                                              categories && categories[0] ? (
                                                <Link
                                                  to={UrlAssembler.articleCategory(
                                                    categories[0].uri,
                                                    categories[0].type,
                                                    { locale: currentLocale },
                                                  )}
                                                  title={categories[0].name}
                                                >
                                                  {categories[0].name}
                                                </Link>
                                              ) : null
                                            }
                                            description={summary}
                                            date={
                                              !isTablet && (
                                                <FormattedDate
                                                  value={new Date(publishedDate)}
                                                  year="numeric"
                                                  month="short"
                                                  day="2-digit"
                                                >
                                                  {(dateLabel: string) => dateLabel}
                                                </FormattedDate>
                                              )
                                            }
                                            author={
                                              <FormattedMessage
                                                id="common.byName"
                                                values={{
                                                  name: authorUrl ? (
                                                    <Link to={authorUrl} title={author.fullName}>
                                                      {author.fullName}
                                                    </Link>
                                                  ) : (
                                                    author.fullName
                                                  ),
                                                }}
                                              />
                                            }
                                            share={
                                              <Share
                                                theme="dark"
                                                contentKey="article"
                                                contentValues={{ title }}
                                                image={generateSrcForCDN(
                                                  extractImageFileFromData.articleImage(images),
                                                )}
                                                url={`${origin}${articleUrl}`}
                                              />
                                            }
                                          >
                                            <Link to={articleUrl} title={title}>
                                              <PicturePlaceholder
                                                type="slider.gridLarge"
                                                artDirectionKey="article.main"
                                                image={extractImageFileFromData.articleImage(
                                                  images,
                                                )}
                                                alt={title}
                                                lazyload={LAZYLOAD_ARTICLE_CONFIG}
                                              />
                                            </Link>
                                          </CardArticle>
                                        )}
                                      </MediaQuery>
                                    );
                                  },
                                )
                              : null}
                          </HorizontalSlider>
                        ) : null}
                        {SubscribeForm || null}
                      </SectionContent>
                    </Wrapper>
                  </Section>
                );
              }}
            </EmailSubscriptionSection>
          )}
          {/* eslint-disable-next-line */}
          {loadingCuratorsPicks ? (
            <React.Fragment>
              <DataProviderPreloaderPage
                uniqueId={PRELOADER_SUBSCRIBER_IDS.curatorsPicks}
                attach={loading}
              />
              <Section>
                <Wrapper
                  sizes={{
                    xs: 'wide',
                    sm: 'wide',
                    md: 'wide',
                    lg: 'narrow',
                    xl: 'narrow',
                  }}
                >
                  <SectionContent title={formatMessage({ id: 'components.blog.curatorsPicks' })}>
                    <GridSquare columnCount={2} justify="space-between">
                      <GridSquareItem columnCount={2}>
                        <CardArticle displayStyle={2} placeholder />
                      </GridSquareItem>
                      <GridSquareItem columnCount={2}>
                        <GridHorizontal>
                          {Array.apply(0, Array(4)).map((item, idx) => (
                            <GridHorizontalItem key={idx} columnCount="1">
                              <CardArticle placeholder displayStyle={3} />
                            </GridHorizontalItem>
                          ))}
                        </GridHorizontal>
                      </GridSquareItem>
                    </GridSquare>
                  </SectionContent>
                </Wrapper>
              </Section>
            </React.Fragment>
          ) : // eslint-disable-next-line
          errorCuratorsPicks ? null : mainCuratorArticle ? (
            <Section>
              <Wrapper
                sizes={{
                  xs: 'wide',
                  sm: 'wide',
                  md: 'wide',
                  lg: 'narrow',
                  xl: 'narrow',
                }}
              >
                <SectionContent title={formatMessage({ id: 'components.blog.curatorsPicks' })}>
                  <GridSquare columnCount={2} justify="space-between">
                    <GridSquareItem columnCount={2}>
                      <MediaQuery minWidth={raScreenSm} maxWidth={raScreenMdMax}>
                        {(isTablet) => (
                          <CardArticle
                            displayStyle={2}
                            title={
                              <Link to={mainCuratorArticleUrl} title={mainCuratorArticle.title}>
                                {mainCuratorArticle.title}
                              </Link>
                            }
                            subtitle={
                              mainCuratorArticle.categories && mainCuratorArticle.categories[0] ? (
                                <Link
                                  to={UrlAssembler.articleCategory(
                                    mainCuratorArticle.categories[0].uri,
                                    mainCuratorArticle.categories[0].type,
                                    { locale: currentLocale },
                                  )}
                                  title={mainCuratorArticle.categories[0].name}
                                >
                                  {mainCuratorArticle.categories[0].name}
                                </Link>
                              ) : null
                            }
                            description={mainCuratorArticle.summary}
                            date={
                              !isTablet && (
                                <FormattedDate
                                  value={new Date(mainCuratorArticle.publishedDate)}
                                  year="numeric"
                                  month="short"
                                  day="2-digit"
                                >
                                  {(dateLabel: string) => dateLabel}
                                </FormattedDate>
                              )
                            }
                            author={
                              <FormattedMessage
                                id="common.byName"
                                values={{
                                  name: mainCuratorAuthorUrl ? (
                                    <Link
                                      to={mainCuratorAuthorUrl}
                                      title={mainCuratorArticle.author.fullName}
                                    >
                                      {mainCuratorArticle.author.fullName}
                                    </Link>
                                  ) : (
                                    mainCuratorArticle.author.fullName
                                  ),
                                }}
                              />
                            }
                            share={
                              <Share
                                theme="dark"
                                contentKey="article"
                                contentValues={{ title: mainCuratorArticle.title }}
                                image={generateSrcForCDN(
                                  extractImageFileFromData.articleImage(mainCuratorArticle.images),
                                )}
                                url={`${origin}${mainCuratorArticleUrl}`}
                                buttonText={formatMessage({ id: 'common.share' })}
                              />
                            }
                          >
                            <Link to={mainCuratorArticleUrl} title={mainCuratorArticle.title}>
                              <PicturePlaceholder
                                type="blog.singleCardFeatured"
                                artDirectionKey="article.main"
                                image={extractImageFileFromData.articleImage(
                                  mainCuratorArticle.images,
                                )}
                                alt={mainCuratorArticle.title}
                                lazyload={LAZYLOAD_ARTICLE_CONFIG}
                              />
                            </Link>
                          </CardArticle>
                        )}
                      </MediaQuery>
                    </GridSquareItem>
                    {restCuratorFeaturedArticles && restCuratorFeaturedArticles.length ? (
                      <GridSquareItem columnCount={2}>
                        <GridHorizontal>
                          {restCuratorFeaturedArticles.map(
                            ({
                              id,
                              type,
                              slug,
                              title,
                              summary,
                              publishedDate,
                              images,
                              author,
                              categories,
                            }) => {
                              const articleUrl = UrlAssembler.articleDetail(id, slug, type);
                              const authorUrl = author
                                ? UrlAssembler.authorDetail(
                                    author.type,
                                    author.typeId,
                                    author.typeAlias,
                                    { locale: currentLocale },
                                  )
                                : null;
                              return (
                                <GridHorizontalItem key={id} columnCount="1">
                                  <MediaQuery minWidth={raScreenSm} maxWidth={raScreenMdMax}>
                                    {(isTablet) => (
                                      <CardArticle
                                        displayStyle={3}
                                        title={
                                          <Link to={articleUrl} title={title}>
                                            {title}
                                          </Link>
                                        }
                                        subtitle={
                                          categories && categories[0] ? (
                                            <Link
                                              to={UrlAssembler.articleCategory(
                                                categories[0].uri,
                                                categories[0].type,
                                                { locale: currentLocale },
                                              )}
                                              title={categories[0].name}
                                            >
                                              {categories[0].name}
                                            </Link>
                                          ) : null
                                        }
                                        description={summary}
                                        date={
                                          !isTablet && (
                                            <FormattedDate
                                              value={new Date(publishedDate)}
                                              year="numeric"
                                              month="short"
                                              day="2-digit"
                                            >
                                              {(dateLabel: string) => dateLabel}
                                            </FormattedDate>
                                          )
                                        }
                                        author={
                                          <FormattedMessage
                                            id="common.byName"
                                            values={{
                                              name: authorUrl ? (
                                                <Link to={authorUrl} title={author.fullName}>
                                                  {author.fullName}
                                                </Link>
                                              ) : (
                                                author.fullName
                                              ),
                                            }}
                                          />
                                        }
                                        share={
                                          <Share
                                            theme="dark"
                                            contentKey="article"
                                            contentValues={{ title }}
                                            image={generateSrcForCDN(
                                              extractImageFileFromData.articleImage(images),
                                            )}
                                            url={`${origin}${articleUrl}`}
                                          />
                                        }
                                      >
                                        <Link to={articleUrl} title={title}>
                                          <PicturePlaceholder
                                            type="blog.gridTwoCol"
                                            artDirectionKey="article.main"
                                            image={extractImageFileFromData.articleImage(images)}
                                            alt={title}
                                            lazyload={LAZYLOAD_ARTICLE_CONFIG}
                                          />
                                        </Link>
                                      </CardArticle>
                                    )}
                                  </MediaQuery>
                                </GridHorizontalItem>
                              );
                            },
                          )}
                        </GridHorizontal>
                      </GridSquareItem>
                    ) : null}
                  </GridSquare>
                </SectionContent>
              </Wrapper>
            </Section>
          ) : null}
        </Fragment>
      ) : null}
    </LayoutDefault>
  );
}
